import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from "react";
import { Alert, Button, Figure, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import Logo from '../assets/jtts_logo.png';
import '../style/sign-in.css';

export default function Login() {
    const [authenticated, setAuthenticated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [ruasSelected, setRuasSelected] = useState('');
    const [selectedRuas, setSelectedRuas] = useState(null);

    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        email: Yup.string().required('Tidak boleh kosong'),
        password: Yup.string().required('Tidak boleh kosong').min(3, 'Password harus lebih dari 3 karakter'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formSchema) });

    // Daftar users dan ruas
    const users = [
        // { username: "CctvDivisi", password: "CctvDivisi123!", value: '99' },
        { username: "Atp2024", password: "External2024", value: '1' },
        { username: "Jorrs2024", password: "External2024", value: '2' },
        { username: "Palindra2024", password: "External2024", value: '3' },
        { username: "Mebi2024", password: "External2024", value: '4' },
        { username: "Bakter2024", password: "External2024", value: '5' },
        { username: "Terpeka2024", password: "External2024", value: '6' },
        { username: "Permai2024", password: "External2024", value: '7' },
        { username: "Sibanceh2024", password: "External2024", value: '8' },
        { username: "Binsa2024", password: "External2024", value: '9' },
        { username: "Pekbang2024", password: "External2024", value: '10' },
        { username: "Bengtab2024", password: "External2024", value: '11' },
        { username: "Indraprabu2024", password: "External2024", value: '12' },
        { username: "Inkis2024", password: "External2024", value: '13' },
        { username: "Betejam2024", password: "External2024", value: '14' },
        { username: "Pacin2024", password: "External2024", value: '15' },
    ];

    const namaRuas = [
        // { name: 'Divisi', alias: 'Divisi', value: '99' },
        { name: 'Ruas ATP', alias: 'ATP', value: '1' },
        { name: 'Ruas JORRS', alias: 'JORRS', value: '2' },
        { name: 'Ruas PALINDRA', alias: 'PALINDRA', value: '3' },
        { name: 'Ruas MEBI', alias: 'MEBI', value: '4' },
        { name: 'Ruas BAKTER', alias: 'BAKTER', value: '5' },
        { name: 'Ruas TERPEKA', alias: 'TERPEKA', value: '6' },
        { name: 'Ruas PERMAI', alias: 'PERMAI', value: '7' },
        { name: 'Ruas SIBANCEH', alias: 'SIBANCEH', value: '8' },
        { name: 'Ruas BINSA', alias: 'BINSA', value: '9' },
        { name: 'Ruas PEKBANG', alias: 'PEKBANG', value: '10' },
        { name: 'Ruas BENGTAB', alias: 'BENGTAB', value: '11' },
        { name: 'Ruas INDRAPRABU', alias: 'INDRAPRABU', value: '12' },
        { name: 'Ruas INKIS', alias: 'INKIS', value: '13' },
        { name: 'Ruas BETAJAM', alias: 'BETAJAM', value: '14' },
        { name: 'Ruas PACIN', alias: 'PACIN', value: '15' },
    ];

    // Function to handle dropdown change
    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedRuas(selectedValue);
    };

    const onSubmit = (data) => {
        const { email, password } = data;

        const account = users.find(user => user.username === email && user.password === password);

        if (account && selectedRuas === account.value) {
            setAuthenticated(true);
            localStorage.setItem("authenticated", true);
            localStorage.setItem("ruasSelected", selectedRuas);

            navigate(selectedRuas === '99' ? '/' : '/cctv/grid');
        } else {
            setAuthenticated(false);
            setShowAlert(true);
            localStorage.setItem("authenticated", false);
            localStorage.setItem("ruasSelected", 0);
        }
    };

    // useEffect to check authentication status
    useEffect(() => {
        const isAuthenticated = localStorage.getItem("authenticated") === 'true';
        if (isAuthenticated) {
            navigate('/cctv/grid');
        }
    }, [authenticated, navigate]);

    return (
        <main className='form-signin m-auto text-center'>
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                {errors.email && <Alert variant="warning" className='w-100'>{errors.email.message}</Alert>}
                {errors.password && <Alert variant="warning" className='w-100'>{errors.password.message}</Alert>}
                {showAlert && <Alert variant="danger" className='w-100'>Anda tidak mempunyai akses untuk Ruas Tol tersebut.</Alert>}

                <Form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                    <Figure>
                        <Figure.Image
                            width={100}
                            height={100}
                            alt='logo-hk'
                            src={Logo}
                        />
                    </Figure>
                    <h1 className='h3 mb-3'>Please sign in</h1>

                    <div className="form-floating">
                        <Form.Control type="text" name="email" className="form-control" placeholder="Email" {...register('email')} />
                        <label>Username</label>
                    </div>

                    <div className="form-floating">
                        <Form.Control type="password" name="password" className="form-control" placeholder="Password" {...register('password')} />
                        <label>Password</label>
                    </div>

                    <div className="">
                        <Form.Control as="select" onChange={handleChange} defaultValue="">
                            <option value="" disabled>Pilih Ruas Tol</option>
                            {namaRuas.map((ruas, index) => (
                                <option key={index} value={ruas.value}>{ruas.name}</option>
                            ))}
                        </Form.Control>
                    </div>

                    <Button size="md" className="w-100 mt-3" as="input" type="submit" value="Login" />
                    <p className="mt-5 mb-3 text-body-secondary">&copy; 2017–2023</p>
                    <small className='h6'>This website is intended for external purpose</small>
                </Form>
            </div>
        </main>
    );
}

// import { yupResolver } from '@hookform/resolvers/yup'
// import React, { useState } from "react"
// import { Alert, Button, Figure, Form } from 'react-bootstrap'
// import { useForm } from 'react-hook-form'
// import { Navigate, useNavigate } from "react-router-dom"
// import * as Yup from 'yup'
// import Logo from '../assets/jtts_logo.png'
// import '../style/sign-in.css'


// export default function Login() {

//     const formSchema = Yup.object().shape({
//         password: Yup.string()
//             .required('Tidak boleh kosong')
//             .min(3, 'Password harus lebih dari 3 karakter'),
//         email: Yup.string()
//             .required('Tidak boleh kosong')
//     })

//     const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated") || false))
//     const [ruasSelected, setRuasSelected] = useState('')
//     const [show, setShow] = useState(false)
//     const navigate = useNavigate();
//     const users = [
//         { username: "PON2024", password: "Pon2024!", value: '8' },
//         // { username: "CctvDivisi", password: "CctvDivisi123!", value: '99' },
//         // { username: "Jorrs2023", password: "Lebaran2023", value: '2' },
//         // { username: "Palindra2023", password: "Lebaran2023", value: '3' },
//         // { username: "Mebi2023", password: "Lebaran2023", value: '4' },
//         // { username: "Bakter2023", password: "Lebaran2023", value: '5' },
//         // { username: "Terpeka2023", password: "Lebaran2023", value: '6' },
//         // { username: "Permai2023", password: "Lebaran2023", value: '7' },
//         // { username: "Sibanceh2023", password: "Lebaran2023", value: '8' },
//         // { username: "Binsa2023", password: "Lebaran2023", value: '9' },
//     ]

//     const namaRuas = [
//         { name: 'Pilih Ruas', alias: null, value: '0' },
//         // { name: 'Divisi', alias: 'Divisi', value: '99' },
//         // { name: 'Ruas JORRS', alias: 'JORRS', value: '2' },
//         // { name: 'Ruas ATP', alias: 'ATP', value: '1' },
//         // { name: 'Ruas BAKTER', alias: 'BAKTER', value: '5' },
//         // { name: 'Ruas TERPEKA', alias: 'TERPEKA', value: '6' },
//         // { name: 'Ruas PALINDRA', alias: 'PALINDRA', value: '3' },
//         // { name: 'Ruas MEBI', alias: 'MEBI', value: '4' },
//         // { name: 'Ruas PERMAI', alias: 'PERMAI', value: '7' },
//         { name: 'Ruas SIBANCEH', alias: 'SIBANCEH', value: '8' },
//         // { name: 'Ruas BINSA', alias: 'BINSA', value: '9' },
//         // { name: 'Ruas PEKBANG', alias: 'PEKBANG', value: '10' },
//         // { name: 'Ruas BENGTAB', alias: 'BENGTAB', value: '11' },
//         // { name: 'Ruas INDRAPRABU', alias: 'INDRAPRABU', value: '12' },
//         // { name: 'Ruas INKIS', alias: 'INKIS', value: '13' },
//     ]

//     const formOptions = { resolver: yupResolver(formSchema) }
//     const { register, handleSubmit, reset, formState } = useForm(formOptions)
//     const { errors } = formState

//     const [selected, setSelected] = useState(null)

//     const handleChange = (selectedOption) => {
//         setSelected(selectedOption)
//     }

//     function onSubmit(data) {

//         const postData = {
//             email: data.email,
//             password: data.password,
//             ruas: selected.target.value
//         }

//         const account = users.find((dataAccount) => dataAccount.username === data.email)
//         const password = account.password == data.password
//         const ruas = account.value == selected.target.value
//         console.log(account + " " + password + " " + ruas);

//         if (account && password && ruas) {
//             setauthenticated(true)
//             setRuasSelected(selected.target.value)
//             setShow(false)
//             localStorage.setItem("authenticated", true)
//             localStorage.setItem("ruasSelected", selected.target.value)
//             // console.log("is otentifikasi ok?", localStorage.getItem("authenticated"))
//             // console.log("ruas terpilih?", localStorage.getItem("ruasSelected"))
//             // alert(localStorage.getItem("ruasSelected"))
//             if (localStorage.getItem("ruasSelected") == '99') {
//                 navigate('/')
//                 // alert('login divisi berhasil')
//             } else {
//                 navigate('/cctv/grid')   
//                 // alert('login cabang berhasil')
//             }
//         } else {
//             setauthenticated(false)
//             setRuasSelected(0)
//             setShow(true)
//             localStorage.setItem("authenticated", false)
//             localStorage.setItem("ruasSelected", 0)
//             console.log("is otentifikasi ok?", localStorage.getItem("authenticated"))
//             console.log("ruas terpilih?", localStorage.getItem("ruasSelected"))
//             navigate('/login')
//         }

//         return false
//     }

//     console.log("is true?", localStorage.getItem("authenticated"))
//     if (localStorage.getItem("authenticated") == 'true') {
//         return <Navigate replace to="/cctv/grid" />
//     } else {
//         return (
//             <>
//                 <main className='form-signin m-auto text-center'>
//                     <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
//                         {
//                             errors.email != undefined ?
//                                 <><Alert show={true} variant="warning" className='w-100'>
//                                     Username {errors.email?.message}
//                                 </Alert></> : <></>
//                         }
//                         {
//                             errors.password != undefined ?
//                                 <><Alert show={true} variant="warning" className='w-100'>
//                                     Password {errors.password?.message}
//                                 </Alert></> : <></>
//                         }
//                         <Alert show={show} variant="danger" className='w-100'>
//                             <Alert.Heading>Warning.</Alert.Heading>
//                             <p>Anda tidak mempunyai akses untuk Ruas Tol tersebut.</p>
//                         </Alert>
//                         <Form onSubmit={handleSubmit(onSubmit)} className='w-100'>
//                             <Figure>
//                                 <Figure.Image
//                                     width={100}
//                                     height={100}
//                                     alt='logo-hk'
//                                     src={Logo}
//                                 />
//                             </Figure>
//                             <h1 className='h3 mb-3'>Please sign in</h1>
//                             <div class="form-floating">
//                                 <Form.Control type='text' name='email' className='form-control' id='floatingInput' placeholder="Email" {...register('email')} />
//                                 <label for="floatingInput">Username</label>
//                             </div>
//                             <div class="form-floating">
//                                 <Form.Control type="password" name='password' className='form-control' id='floatingPassword' placeholder="Password" {...register('password')} />
//                                 <label for="floatingPassword">Password</label>
//                             </div>
//                             <div class="">
//                                 <Form.Control as={'select'} onChange={handleChange}>
//                                     {
//                                         namaRuas.map((result, index) => {
//                                             return (
//                                                 <>
//                                                     <option value={result.value}>{result.name}</option>
//                                                 </>
//                                             )
//                                         })
//                                     }
//                                 </Form.Control>
//                             </div>

//                             <Button size="md" className='w-100 mt-3' as="input" type="submit" value="Login" />
//                             <p class="mt-5 mb-3 text-body-secondary">&copy; 2017–2024</p>
//                             <small className='h6'>This website is intended for external purpose</small>
//                         </Form>
//                     </div>
//                 </main>
//             </>
//         )
//     }
// }